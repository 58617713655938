@tailwind base;
@tailwind components;
@tailwind utilities;


/* Añade esto a tu archivo CSS */
.loader {
    border-top-color: #3498db;
    animation: spinner 1.5s linear infinite;
  }
  
  @keyframes spinner {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  /* Añade esto a tu archivo CSS global o al módulo CSS */
.preformatted {
  white-space: pre-wrap; /* CSS3 */
  white-space: -moz-pre-wrap; /* Firefox */
  white-space: -pre-wrap; /* Opera <7 */
  white-space: -o-pre-wrap; /* Opera 7 */
  word-wrap: break-word; /* IE */
}
.quill-content img {
  max-width: 100%;
  height: auto;
  display: block;
  margin: 10px 0;
}

.quill-content p {
  margin-bottom: 1rem;
}

/* styles.css o el archivo CSS que prefieras */
.quill-editor .ql-editor h1,
.quill-editor .ql-editor h2,
.quill-editor .ql-editor h3,
.quill-editor .ql-editor h4,
.quill-editor .ql-editor h5,
.quill-editor .ql-editor h6 {
  color: white !important;
}
