/* datagrid.css */

.ag-theme-quartz-dark {
    --ag-background-color: transparent;
    --ag-header-background-color: transparent; /* Cambia el color de fondo del encabezado */
    --ag-font-size: 14px; /* Cambia el tamaño de la fuente */
    --ag-border-color: transparent; /* Eliminar los bordes laterales */

    /* Puedes agregar más variables CSS personalizadas o clases aquí */
  }
  
  /* Ajustar la opacidad del menú de filtro */
  .ag-theme-quartz-dark .ag-filter-wrapper {
    background-color: #05141f;
  }
  
  .ag-theme-quartz-dark .ag-filter-input {
    background-color: #05141f;
  }
  
  .ag-theme-quartz-dark .ag-floating-filter-input {
    background-color: #05141f;
  }
  
  .ag-theme-quartz-dark .ag-popup {
    background-color: #05141f;
  }
  
  .ag-theme-quartz-dark .ag-menu {
    background-color: #05141f;
  }
  
  .ag-theme-quartz-dark .ag-menu-option {
    background-color: #05141f;
  }
  
  .ag-theme-quartz-dark .ag-menu-option-active {
    background-color: #05141f;
  }
  
  .parent-row {
    background-color: #05141f; /* Color más oscuro para las filas principales */
  }
  
  .child-row {
    background-color: #05192ce7; /* Color más claro para las filas secundarias */
  }
  
  
  .transition-height {
    transition: height 0.3s ease-in-out;
  }
  
  .action-buttons button {
    transition: transform 0.3s;
  }
  
  .action-buttons button:hover {
    transform: scale(1.1);
  }
  